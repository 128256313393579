* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.CourseDetails .content .body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 24px; }
  .CourseDetails .content .body .max-width {
    max-width: 1400px;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto 1fr;
    gap: 24px;
    padding: 24px 0;
    align-items: flex-start; }
    .CourseDetails .content .body .max-width .ContentPanel.main-content {
      overflow: hidden;
      grid-row: 1 / span 2;
      grid-column: 1; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content .title-container {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        gap: 24px;
        align-items: center; }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .title-container .Button {
          margin-bottom: 24px; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%; }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section .SkeletonText {
          margin-bottom: 8px; }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section p,
        .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ul,
        .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ol {
          margin-bottom: 24px;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.2em; }
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section p img,
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ul img,
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ol img {
            border-radius: 12px;
            box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.4); }
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section p a,
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ul a,
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ol a {
            padding: 24px 36px;
            background-color: rgba(255, 255, 255, 0.125);
            backdrop-filter: blur(25px) brightness(200%);
            border-radius: 12px;
            display: inline-block;
            font-weight: 700;
            text-decoration: none;
            transition: transform 0.2s ease-in-out; }
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section p a:hover,
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ul a:hover,
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ol a:hover {
              backdrop-filter: blur(25px);
              transform: scale(1.05); }
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section p a:active,
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ul a:active,
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ol a:active {
              background-color: rgba(255, 255, 255, 0.3);
              backdrop-filter: blur(25px);
              transform: scale(0.95);
              transition: none; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content h1,
      .CourseDetails .content .body .max-width .ContentPanel.main-content h2,
      .CourseDetails .content .body .max-width .ContentPanel.main-content h3,
      .CourseDetails .content .body .max-width .ContentPanel.main-content h4 {
        margin-bottom: 24px; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content h1 {
        font-size: 28px; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content .image-scroller {
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x proximity;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        padding: 24px 48px;
        margin: -24px -48px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */ }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .image-scroller::-webkit-scrollbar {
          display: none; }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .image-scroller .images {
          display: grid;
          gap: 24px;
          grid-template-columns: auto;
          grid-auto-columns: auto;
          grid-auto-flow: column; }
          .CourseDetails .content .body .max-width .ContentPanel.main-content .image-scroller .images .Image {
            scroll-snap-align: center; }
          .CourseDetails .content .body .max-width .ContentPanel.main-content .image-scroller .images .end-spacer {
            width: 24px; }
    .CourseDetails .content .body .max-width .ContentPanel.play-now {
      height: 400px;
      grid-row: 1;
      align-self: stretch;
      display: grid;
      grid-template-rows: auto auto 1fr auto;
      align-items: flex-end;
      position: relative;
      overflow: hidden; }
      .CourseDetails .content .body .max-width .ContentPanel.play-now img.background-image {
        position: absolute;
        grid-row: 200;
        grid-column: 200;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -2; }
      .CourseDetails .content .body .max-width .ContentPanel.play-now .background-overlay {
        position: absolute;
        grid-row: 200;
        grid-column: 200;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        backdrop-filter: brightness(100%); }
      .CourseDetails .content .body .max-width .ContentPanel.play-now .Button {
        grid-row: 4; }
    .CourseDetails .content .body .max-width .ContentPanel.quick-facts .quick-fact,
    .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info {
      display: grid;
      grid-template-columns: 16px auto 1fr;
      gap: 16px;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px; }
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .quick-fact svg,
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info svg {
        justify-self: center;
        align-self: center;
        text-align: center; }
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .quick-fact .title,
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .quick-fact .value,
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info .title,
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info .value {
        font-size: 14px;
        font-weight: 500; }
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .quick-fact .value,
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info .value {
        text-align: right; }
    .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info {
      grid-template-columns: auto 1fr; }
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info:last-child {
        margin-bottom: 0; }

@media screen and (max-width: 1080px) {
  .CourseDetails .content .body .max-width {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    padding: 12px 0; }
    .CourseDetails .content .body .max-width .ContentPanel.main-content {
      grid-row: unset;
      grid-column: span 2; }
    .CourseDetails .content .body .max-width .ContentPanel.play-now {
      height: auto; }
    .CourseDetails .content .body .max-width .ContentPanel.quick-facts {
      grid-row: 1;
      align-self: stretch; } }

@media screen and (max-width: 640px) {
  .CourseDetails .content .body .max-width {
    grid-auto-flow: row;
    grid-template-rows: auto;
    grid-template-columns: 1fr; }
    .CourseDetails .content .body .max-width .ContentPanel.main-content {
      height: auto;
      grid-row: unset;
      grid-column: unset;
      padding: 24px; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content .title-container {
        grid-template-rows: 1fr auto; }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .title-container .Title {
          width: 100%;
          font-size: 12px; }
    .CourseDetails .content .body .max-width .ContentPanel.play-now {
      grid-row: 1;
      height: 400px; }
    .CourseDetails .content .body .max-width .ContentPanel.quick-facts {
      grid-row: 2; } }
