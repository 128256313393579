.Settings {
    z-index: 999999999;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #00310520;
    backdrop-filter: brightness(30%) blur(44px);
    animation: fadeIn ease-in-out 0.522s;
    -webkit-animation: fadeIn ease 0.522s;
    -moz-animation: fadeIn ease 0.522s;
    -o-animation: fadeIn ease 0.522s;
    -ms-animation: fadeIn ease 0.522s;
    .ModalHeader {
        max-width: 680px;
        h2 {
            font-size: 42px;
            text-align: center;
        }
        .RefillPrompt {
            margin: 0 auto;
            padding: 12px;
            p {
                font-size: 14px;
            }
            .action {
                font-size: 14px;
                font-weight: 600;
                color: #00c4ff;
                cursor: pointer;
            }
        }
    }
    .SettingsContent {
        width: 100%;
        max-width: 680px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .Inputs {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 12px;
            .InputContainer {
                h2 {
                    font-size: 18px;
                    font-weight: 400;
                }
                input {
                    padding: 12px;
                    width: 100%;
                    border-radius: 8px;
                    margin: 8px 0;
                    border: none;
                    color:black;
                }
            }
        }
    }
    .Buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 12px;
        max-width: 680px;

        .button {
            padding: 12px;
            text-align: center;
            border-radius: 12px;
            width: 100%;
            background-color: #00c4ff;
            margin: 6px 0;
            cursor: pointer;
            h2 {
                font-weight: 500;
                font-size: 18px;
            }
        }
        .buttonClose {
            padding: 12px;
            text-align: center;
            border-radius: 12px;
            font-size: 14px;
            width: 100%;
            background-color: grey;
            margin: 6px 0;
            cursor: pointer;
            h2 {
                font-weight: 500;
                font-size: 18px;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 600px) {
    .ModalHeader {
        max-width: 680px;
        padding: 24px 0;
        padding-bottom: 12px;
        h2 {
            font-size: 22px !important;
        }
    }

    .InputContainer {
        h2 {
            font-size: 14px !important;
            font-weight: 400;
        }
        input {
            padding: 12px;
            width: 100%;
            border-radius: 8px;
            margin: 8px 0;
            border: none;
            color:black;
        }
    }
}