* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Header {
  width: 100%;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(22px) brightness(30%);
  padding: 0 24px;
  height: 72px;
  position: fixed;
  z-index: 9999; }
  .Header .header-content {
    width: 100%;
    max-width: 1400px;
    padding: 12px 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    gap: 12px;
    align-items: center; }
    .Header .header-content .header-logo {
      width: 48px;
      height: 48px; }
    .Header .header-content .header-title {
      font-size: 32px;
      font-weight: 600;
      margin-left: 12px; }
    .Header .header-content .num-of-plays-container.collapse {
      width: 48px;
      height: 48px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
    .Header .header-content .profile-container.collapse {
      width: 48px;
      height: 48px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
    .Header .header-content .sign-in-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .Header .header-content .sign-in-button p {
        margin-left: 12px; }
