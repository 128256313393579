* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Banner {
  padding: 0 24px;
  padding-top: calc(48px + 72px);
  padding-bottom: 48px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 400px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.4); }
  .Banner .hero-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }
  .Banner img.background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .Banner .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px) brightness(80%); }
  .Banner .inner {
    width: 100%;
    max-width: 1400px;
    position: relative; }
    .Banner .inner .back-button-link {
      position: absolute;
      top: 0;
      left: 0;
      text-decoration: none; }
    .Banner .inner .logo-skeleton {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      filter: drop-shadow(0 4px 12px black); }
    .Banner .inner .course-logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      filter: drop-shadow(0 4px 12px black); }

@media screen and (max-width: 1080px) {
  .Banner {
    padding-bottom: 24px;
    height: unset; }
    .Banner .inner {
      display: grid;
      gap: 24px;
      grid-template-rows: auto auto; }
      .Banner .inner .back-button-link {
        position: initial;
        grid-row: 1;
        justify-self: flex-start; }
      .Banner .inner .logo-skeleton {
        position: initial; }
      .Banner .inner .course-logo {
        position: initial;
        height: auto;
        max-height: 200px; } }
