* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.PurchaseCreditsPopup {
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 12px; }
  .PurchaseCreditsPopup .PurchaseFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    padding: 36px;
    margin: 24px;
    max-width: 100%; }
    .PurchaseCreditsPopup .PurchaseFlex .Heading h1 {
      margin-bottom: 24px;
      display: flex; }
    .PurchaseCreditsPopup .PurchaseFlex .Heading p {
      margin-bottom: 12px;
      display: flex; }
    .PurchaseCreditsPopup .PurchaseFlex .CardInformation {
      display: flex;
      flex-direction: column; }
      .PurchaseCreditsPopup .PurchaseFlex .CardInformation .PurchaseFull {
        width: 100%;
        display: flex;
        flex-direction: column; }
        .PurchaseCreditsPopup .PurchaseFlex .CardInformation .PurchaseFull .PurchaseInput {
          padding: 8px;
          border-radius: 8px;
          border-style: none;
          margin: 4px 0; }
      .PurchaseCreditsPopup .PurchaseFlex .CardInformation .PurchaseHalves {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 4px 0; }
        .PurchaseCreditsPopup .PurchaseFlex .CardInformation .PurchaseHalves .PurchaseEXP {
          display: flex;
          flex-direction: column;
          width: 49%;
          padding: 8px 6px;
          border-radius: 8px;
          border-style: none; }
        .PurchaseCreditsPopup .PurchaseFlex .CardInformation .PurchaseHalves .PurchaseCVV {
          display: flex;
          flex-direction: column;
          width: 49%;
          padding: 8px 6px;
          border-radius: 8px;
          border-style: none; }
    .PurchaseCreditsPopup .PurchaseFlex .PricingOptionContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    .PurchaseCreditsPopup .PurchaseFlex .PlayerTerms {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0; }
      .PurchaseCreditsPopup .PurchaseFlex .PlayerTerms .Lefty {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 10%; }
      .PurchaseCreditsPopup .PurchaseFlex .PlayerTerms .Righty {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%; }
        .PurchaseCreditsPopup .PurchaseFlex .PlayerTerms .Righty p {
          font-size: 12px; }
    .PurchaseCreditsPopup .PurchaseFlex .buttons-container {
      display: flex;
      flex-direction: column;
      gap: 12px; }
      .PurchaseCreditsPopup .PurchaseFlex .buttons-container .Button {
        background-color: #00c4ff;
        backdrop-filter: none; }
        .PurchaseCreditsPopup .PurchaseFlex .buttons-container .Button:active {
          transform: scale(0.95); }

@media only screen and (max-width: 600px) {
  .PurchaseFlex {
    padding: 18px !important; }
  .Heading {
    margin: 0 auto; }
    .Heading h1 {
      font-size: 28px;
      margin: 12px 0; } }
