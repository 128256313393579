* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.menu-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px) brightness(50%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  padding: 0 24px; }
  .menu-backdrop .menu-container {
    margin-top: 72px;
    max-width: 1400px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    overflow: hidden; }
    .menu-backdrop .menu-container .menu {
      padding: 12px 24px;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 24px;
      max-width: 250px;
      text-align: center;
      transform: translateY(0%);
      transition: transform 0.2s ease-in-out;
      width: 100%;
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: auto;
      grid-template-rows: auto; }
      .menu-backdrop .menu-container .menu.closed {
        transform: translateY(-50%); }
