.SearchResultItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    width: 100%;
    transition: all ease-in-out .122s;

    &:hover {
        transform: scale(.98);
        transition: transform ease-in-out .255s;
    }
    .image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
        border-radius: 8px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .information {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        .title {
            font-size: 18px;
        }
        .descript {
            font-size: 12px;
        }
    }
}