body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Header {
  width: 100%;
  display: flex;
  justify-content: center;
  -webkit-backdrop-filter: blur(22px) brightness(30%);
          backdrop-filter: blur(22px) brightness(30%);
  padding: 0 24px;
  height: 72px;
  position: fixed;
  z-index: 9999; }
  .Header .header-content {
    width: 100%;
    max-width: 1400px;
    padding: 12px 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-gap: 12px;
    gap: 12px;
    align-items: center; }
    .Header .header-content .header-logo {
      width: 48px;
      height: 48px; }
    .Header .header-content .header-title {
      font-size: 32px;
      font-weight: 600;
      margin-left: 12px; }
    .Header .header-content .num-of-plays-container.collapse {
      width: 48px;
      height: 48px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
    .Header .header-content .profile-container.collapse {
      width: 48px;
      height: 48px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
    .Header .header-content .sign-in-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .Header .header-content .sign-in-button p {
        margin-left: 12px; }

.styles_StripeStyle__35Zl0 {
  background-color: white;
  padding: 8px;
  margin: 8px 0;
  margin-top: 4px;
  border-radius: 8px; }

.styles_ButtonContain__2UQax {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .styles_ButtonContain__2UQax .styles_BuyNow__1FuQV {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: #ffffff !important;
    font-weight: 600;
    font-size: 18px !important;
    background-color: #00c4ff;
    padding: 14px;
    border: none;
    border-radius: 12px;
    margin: 8px;
    cursor: pointer; }

.styles_PricingOption__1xRPG {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 8px 0;
  border-radius: 8px;
  background-color: white;
  width: 30%;
  min-height: 140px;
  text-align: center;
  padding: 16px; }
  .styles_PricingOption__1xRPG .styles_Numbers__11gCK .styles_CreditAmount__1brKe {
    display: flex;
    flex-direction: row;
    align-items: baseline; }
  .styles_PricingOption__1xRPG .styles_Radio__ODL5x {
    border: 1px solid #7d8385;
    width: 24px;
    height: 24px;
    border-radius: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important; }
    .styles_PricingOption__1xRPG .styles_Radio__ODL5x img {
      width: 100%;
      padding: 2px; }
    .styles_PricingOption__1xRPG .styles_Radio__ODL5x.styles_selected__uaVoS {
      background-color: #1293ff; }
  .styles_PricingOption__1xRPG h2 {
    color: black !important; }
  .styles_PricingOption__1xRPG p {
    color: black !important; }

@media only screen and (max-width: 600px) {
  .styles_PricingOption__1xRPG h2 {
    font-size: 16px; }
  .styles_PricingOption__1xRPG p {
    font-size: 12px; } }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.PurchaseCreditsPopup {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 12px; }
  .PurchaseCreditsPopup .PurchaseFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    padding: 36px;
    margin: 24px;
    max-width: 100%; }
    .PurchaseCreditsPopup .PurchaseFlex .Heading h1 {
      margin-bottom: 24px;
      display: flex; }
    .PurchaseCreditsPopup .PurchaseFlex .Heading p {
      margin-bottom: 12px;
      display: flex; }
    .PurchaseCreditsPopup .PurchaseFlex .CardInformation {
      display: flex;
      flex-direction: column; }
      .PurchaseCreditsPopup .PurchaseFlex .CardInformation .PurchaseFull {
        width: 100%;
        display: flex;
        flex-direction: column; }
        .PurchaseCreditsPopup .PurchaseFlex .CardInformation .PurchaseFull .PurchaseInput {
          padding: 8px;
          border-radius: 8px;
          border-style: none;
          margin: 4px 0; }
      .PurchaseCreditsPopup .PurchaseFlex .CardInformation .PurchaseHalves {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 4px 0; }
        .PurchaseCreditsPopup .PurchaseFlex .CardInformation .PurchaseHalves .PurchaseEXP {
          display: flex;
          flex-direction: column;
          width: 49%;
          padding: 8px 6px;
          border-radius: 8px;
          border-style: none; }
        .PurchaseCreditsPopup .PurchaseFlex .CardInformation .PurchaseHalves .PurchaseCVV {
          display: flex;
          flex-direction: column;
          width: 49%;
          padding: 8px 6px;
          border-radius: 8px;
          border-style: none; }
    .PurchaseCreditsPopup .PurchaseFlex .PricingOptionContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    .PurchaseCreditsPopup .PurchaseFlex .PlayerTerms {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0; }
      .PurchaseCreditsPopup .PurchaseFlex .PlayerTerms .Lefty {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 10%; }
      .PurchaseCreditsPopup .PurchaseFlex .PlayerTerms .Righty {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%; }
        .PurchaseCreditsPopup .PurchaseFlex .PlayerTerms .Righty p {
          font-size: 12px; }
    .PurchaseCreditsPopup .PurchaseFlex .buttons-container {
      display: flex;
      flex-direction: column;
      grid-gap: 12px;
      gap: 12px; }
      .PurchaseCreditsPopup .PurchaseFlex .buttons-container .Button {
        background-color: #00c4ff;
        -webkit-backdrop-filter: none;
                backdrop-filter: none; }
        .PurchaseCreditsPopup .PurchaseFlex .buttons-container .Button:active {
          -webkit-transform: scale(0.95);
                  transform: scale(0.95); }

@media only screen and (max-width: 600px) {
  .PurchaseFlex {
    padding: 18px !important; }
  .Heading {
    margin: 0 auto; }
    .Heading h1 {
      font-size: 28px;
      margin: 12px 0; } }

.styles_Settings__18a6n {
  z-index: 999999999;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #00310520;
  -webkit-backdrop-filter: brightness(30%) blur(44px);
          backdrop-filter: brightness(30%) blur(44px);
  animation: styles_fadeIn__1b88W ease-in-out 0.522s;
  -webkit-animation: styles_fadeIn__1b88W ease 0.522s;
  -moz-animation: styles_fadeIn__1b88W ease 0.522s;
  -o-animation: styles_fadeIn__1b88W ease 0.522s;
  -ms-animation: styles_fadeIn__1b88W ease 0.522s; }
  .styles_Settings__18a6n .styles_ModalHeader__ykTeL {
    max-width: 680px; }
    .styles_Settings__18a6n .styles_ModalHeader__ykTeL h2 {
      font-size: 42px;
      text-align: center; }
    .styles_Settings__18a6n .styles_ModalHeader__ykTeL .styles_RefillPrompt__2IIeq {
      margin: 0 auto;
      padding: 12px; }
      .styles_Settings__18a6n .styles_ModalHeader__ykTeL .styles_RefillPrompt__2IIeq p {
        font-size: 14px; }
      .styles_Settings__18a6n .styles_ModalHeader__ykTeL .styles_RefillPrompt__2IIeq .styles_action__3VzD4 {
        font-size: 14px;
        font-weight: 600;
        color: #00c4ff;
        cursor: pointer; }
  .styles_Settings__18a6n .styles_SettingsContent__27XFR {
    width: 100%;
    max-width: 680px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .styles_Settings__18a6n .styles_SettingsContent__27XFR .styles_Inputs__1_E1S {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 12px; }
      .styles_Settings__18a6n .styles_SettingsContent__27XFR .styles_Inputs__1_E1S .styles_InputContainer__2pYww h2 {
        font-size: 18px;
        font-weight: 400; }
      .styles_Settings__18a6n .styles_SettingsContent__27XFR .styles_Inputs__1_E1S .styles_InputContainer__2pYww input {
        padding: 12px;
        width: 100%;
        border-radius: 8px;
        margin: 8px 0;
        border: none;
        color: black; }
  .styles_Settings__18a6n .styles_Buttons__gEShV {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 12px;
    max-width: 680px; }
    .styles_Settings__18a6n .styles_Buttons__gEShV .styles_button__3XasP {
      padding: 12px;
      text-align: center;
      border-radius: 12px;
      width: 100%;
      background-color: #00c4ff;
      margin: 6px 0;
      cursor: pointer; }
      .styles_Settings__18a6n .styles_Buttons__gEShV .styles_button__3XasP h2 {
        font-weight: 500;
        font-size: 18px; }
    .styles_Settings__18a6n .styles_Buttons__gEShV .styles_buttonClose__2sRtc {
      padding: 12px;
      text-align: center;
      border-radius: 12px;
      font-size: 14px;
      width: 100%;
      background-color: grey;
      margin: 6px 0;
      cursor: pointer; }
      .styles_Settings__18a6n .styles_Buttons__gEShV .styles_buttonClose__2sRtc h2 {
        font-weight: 500;
        font-size: 18px; }

@keyframes styles_fadeIn__1b88W {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes styles_fadeIn__1b88W {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media screen and (max-width: 600px) {
  .styles_ModalHeader__ykTeL {
    max-width: 680px;
    padding: 24px 0;
    padding-bottom: 12px; }
    .styles_ModalHeader__ykTeL h2 {
      font-size: 22px !important; }
  .styles_InputContainer__2pYww h2 {
    font-size: 14px !important;
    font-weight: 400; }
  .styles_InputContainer__2pYww input {
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    margin: 8px 0;
    border: none;
    color: black; } }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.HeaderButton {
  background-color: rgba(255, 255, 255, 0.125);
  border-radius: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  overflow: visible; }

.ProfileMini {
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  pointer-events: none; }
  .ProfileMini .profile-image {
    width: 32px;
    height: 32px;
    border-radius: 500px;
    border: solid 2px white;
    overflow: hidden; }
    .ProfileMini .profile-image img {
      width: 100%;
      height: 100%; }
  .ProfileMini .content {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .ProfileMini .content .name-title {
      pointer-events: none; }
    .ProfileMini .content .arrow {
      width: 24px;
      height: 24px;
      border-radius: 500px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 4px; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.menu-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(8px) brightness(50%);
          backdrop-filter: blur(8px) brightness(50%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  padding: 0 24px; }
  .menu-backdrop .menu-container {
    margin-top: 72px;
    max-width: 1400px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    overflow: hidden; }
    .menu-backdrop .menu-container .menu {
      padding: 12px 24px;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 24px;
      max-width: 250px;
      text-align: center;
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      transition: -webkit-transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
      width: 100%;
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: auto;
      grid-template-rows: auto; }
      .menu-backdrop .menu-container .menu.closed {
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.MenuItem {
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding: 12px 24px;
  display: inline-block;
  border-radius: 8px;
  -webkit-backdrop-filter: brightness(95%);
          backdrop-filter: brightness(95%);
  margin: 12px 0 0 0; }
  .MenuItem:hover {
    -webkit-backdrop-filter: brightness(15%);
            backdrop-filter: brightness(15%); }
  .MenuItem.Button {
    color: white;
    padding: 12px;
    margin: 12px 0; }
  .MenuItem.Divider {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0;
    margin: 12px 0;
    max-width: 100px;
    background-color: transparent;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    cursor: default; }

.Tile {
  position: relative;
  text-align: center;
  border-radius: 32px;
  transition: -webkit-transform 0.355s ease-in-out;
  transition: transform 0.355s ease-in-out;
  transition: transform 0.355s ease-in-out, -webkit-transform 0.355s ease-in-out;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transition: all ease-in-out .325s; }
  .Tile:before {
    content: " ";
    display: block;
    padding-top: 75%; }
  .Tile:hover {
    transition: all ease-in-out .225s;
    cursor: pointer; }
  .Tile:hover .tile-background, .Tile .tile-background:hover {
    -webkit-filter: blur(8px);
            filter: blur(8px);
    transition: all .555s ease-in-out; }
  .Tile .tile-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    -webkit-filter: blur(22px);
            filter: blur(22px);
    transition: all .355s ease-in-out; }
  .Tile .tile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2); }
    .Tile .tile-overlay.light {
      background-color: rgba(255, 255, 255, 0.15);
      background-color: transparent;
      -webkit-backdrop-filter: blur(4px) brightness(150%);
              backdrop-filter: blur(4px) brightness(150%); }
  .Tile .sponsor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 24px;
    overflow: hidden; }
    .Tile .sponsor img {
      width: 100%;
      align-self: center;
      object-fit: contain;
      z-index: 1;
      -webkit-filter: drop-shadow(0 0 12px black);
              filter: drop-shadow(0 0 12px black); }
    .Tile .sponsor h1 {
      -webkit-filter: drop-shadow(0 0 12px black);
              filter: drop-shadow(0 0 12px black); }
  .Tile h1 {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.TileContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 48px;
  padding-top: calc(48px + 72px); }
  .TileContainer .inner {
    width: 100%;
    max-width: 1400px;
    display: grid;
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-gap: 48px;
    gap: 48px; }

@media only screen and (max-width: 800px) {
  .TileContainer {
    padding-left: 24px;
    padding-right: 24px; } }

.styles_SearchResultItem__5GMvh {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  width: 100%;
  transition: all ease-in-out .122s; }
  .styles_SearchResultItem__5GMvh:hover {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    transition: -webkit-transform ease-in-out .255s;
    transition: transform ease-in-out .255s;
    transition: transform ease-in-out .255s, -webkit-transform ease-in-out .255s; }
  .styles_SearchResultItem__5GMvh .styles_image__1M6VA {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    border-radius: 8px; }
    .styles_SearchResultItem__5GMvh .styles_image__1M6VA img {
      width: 100%;
      height: auto; }
  .styles_SearchResultItem__5GMvh .styles_information__23e8G {
    display: flex;
    flex-direction: column;
    padding: 0 16px; }
    .styles_SearchResultItem__5GMvh .styles_information__23e8G .styles_title__2Ad8O {
      font-size: 18px; }
    .styles_SearchResultItem__5GMvh .styles_information__23e8G .styles_descript__314yc {
      font-size: 12px; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

:root {
  --max-width: 400px; }

.Search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 18px;
  width: 400px;
  width: var(--max-width);
  transition: width 0.2s ease-in-out; }
  .Search.collapse {
    width: 48px;
    height: 48px;
    padding: 0;
    justify-content: center; }
    .Search.collapse .icon {
      margin-right: 0; }
  .Search .icon {
    margin-right: 12px; }
  .Search .SearchContainer {
    animation: fadeIn ease-in-out .655s;
    -webkit-animation: fadeIn ease .655s;
    -moz-animation: fadeIn ease .655s;
    -o-animation: fadeIn ease .655s;
    -ms-animation: fadeIn ease .655s; }
    .Search .SearchContainer .Results {
      background-color: #263a28;
      border-radius: 12px;
      width: 100%;
      max-height: 300px;
      overflow-y: scroll;
      position: absolute;
      margin: 10px 0;
      left: 0;
      right: 0;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      animation: fadeIn ease-in-out 2s;
      -webkit-animation: fadeIn ease 2s;
      -moz-animation: fadeIn ease 2s;
      -o-animation: fadeIn ease 2s;
      -ms-animation: fadeIn ease 2s; }
      .Search .SearchContainer .Results a:link {
        text-decoration: none; }
    .Search .SearchContainer .Results::-webkit-scrollbar {
      display: none; }
  .Search input[type="text"] {
    height: 48px;
    width: 100%;
    border: none;
    background-color: transparent;
    caret-color: #00c4ff;
    font-size: 16px;
    color: white;
    font-weight: 400; }
    .Search input[type="text"]:focus {
      outline: none; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Banner {
  padding: 0 24px;
  padding-top: calc(48px + 72px);
  padding-bottom: 48px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 400px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.4); }
  .Banner .hero-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }
  .Banner img.background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .Banner .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(4px) brightness(80%);
            backdrop-filter: blur(4px) brightness(80%); }
  .Banner .inner {
    width: 100%;
    max-width: 1400px;
    position: relative; }
    .Banner .inner .back-button-link {
      position: absolute;
      top: 0;
      left: 0;
      text-decoration: none; }
    .Banner .inner .logo-skeleton {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      -webkit-filter: drop-shadow(0 4px 12px black);
              filter: drop-shadow(0 4px 12px black); }
    .Banner .inner .course-logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      -webkit-filter: drop-shadow(0 4px 12px black);
              filter: drop-shadow(0 4px 12px black); }

@media screen and (max-width: 1080px) {
  .Banner {
    padding-bottom: 24px;
    height: unset; }
    .Banner .inner {
      display: grid;
      grid-gap: 24px;
      gap: 24px;
      grid-template-rows: auto auto; }
      .Banner .inner .back-button-link {
        position: static;
        position: initial;
        grid-row: 1;
        justify-self: flex-start; }
      .Banner .inner .logo-skeleton {
        position: static;
        position: initial; }
      .Banner .inner .course-logo {
        position: static;
        position: initial;
        height: auto;
        max-height: 200px; } }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Button {
  padding: 12px 18px;
  background-color: rgba(255, 255, 255, 0.125);
  color: white;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-backdrop-filter: blur(20px) brightness(180%);
          backdrop-filter: blur(20px) brightness(180%);
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  text-decoration: none; }
  .Button:hover {
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px); }
  .Button:active {
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    transition: none; }
  .Button.highlight {
    color: #e6bf3b !important; }
    .Button.highlight div {
      color: #e6bf3b !important; }
    .Button.highlight p {
      color: #e6bf3b !important; }
    .Button.highlight svg {
      color: #e6bf3b !important; }
      .Button.highlight svg path {
        color: #e6bf3b !important; }
  .Button.darkMode {
    -webkit-backdrop-filter: blur(20px) brightness(80%);
            backdrop-filter: blur(20px) brightness(80%);
    background-color: rgba(0, 0, 0, 0.125); }
  .Button .divider {
    width: 12px;
    height: 12px;
    background-color: transparent; }

.ContentPanel {
  border-radius: 24px;
  padding: 48px;
  -webkit-backdrop-filter: brightness(150%);
          backdrop-filter: brightness(150%); }
  .ContentPanel.small {
    padding: 24px; }

.Title h1 {
  font-size: 36px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  margin-bottom: 24px; }

.Subtitle h2 {
  font-size: 22px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  margin-bottom: 12px; }

.Divider {
  height: 1px;
  width: 25%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0;
  padding: 0;
  margin-bottom: 16px; }

.SkeletonText {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  overflow: hidden; }
  .SkeletonText ion-skeleton-text {
    --background-rgb: 0, 0, 0; }

.Image img, .Image .SkeletonText {
  border-radius: 12px;
  width: 300px;
  height: 200px; }

.Image img {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.4);
  object-fit: cover;
  object-position: center; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.SignIn {
  padding: 36px;
  border-radius: 24px;
  text-align: left;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 12px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; }
  .SignIn .SignInFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 36px;
    margin: 24px;
    max-width: 100%;
    border-radius: 24px; }
    .SignIn .SignInFlex h1 {
      margin-bottom: 24px;
      display: flex; }
    .SignIn .SignInFlex input[type="text"],
    .SignIn .SignInFlex input[type="password"] {
      padding: 12px;
      font-size: 18px;
      border-radius: 12px;
      margin-bottom: 12px;
      border: none;
      color: black;
      width: 100%; }
    .SignIn .SignInFlex .Button {
      margin-top: 12px;
      background-color: #00c4ff;
      -webkit-backdrop-filter: none;
              backdrop-filter: none;
      width: 100%; }
    .SignIn .SignInFlex .no-account-container {
      display: grid;
      grid-template-columns: 1fr auto;
      margin-top: 24px;
      align-items: center; }
      .SignIn .SignInFlex .no-account-container p {
        margin-right: 12px; }
      .SignIn .SignInFlex .no-account-container .Button {
        margin: 0;
        padding: 8px 12px;
        font-size: 16px;
        background-color: #008db7; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.popup-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(8px) brightness(50%);
          backdrop-filter: blur(8px) brightness(50%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden; }
  .popup-backdrop .popup-spacer {
    width: 100%;
    height: 100%; }

@media screen and (max-width: 400px) {
  .popup-backdrop .popup-content {
    width: 100vw; } }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.popup-backdrop .SignUp {
  padding: 36px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  text-align: left;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-template-rows: auto;
  grid-gap: 12px;
  gap: 12px;
  grid-row: 2;
  grid-column: 2;
  overflow: auto;
  max-height: 100%; }
  .popup-backdrop .SignUp h1 {
    margin-bottom: 24px; }
  .popup-backdrop .SignUp input[type="text"],
  .popup-backdrop .SignUp input[type="password"],
  .popup-backdrop .SignUp input[type="number"] {
    padding: 12px;
    font-size: 18px;
    border-radius: 12px;
    border: none;
    color: black; }
  .popup-backdrop .SignUp .Button {
    margin-top: 12px;
    background-color: #00c4ff;
    -webkit-backdrop-filter: none;
            backdrop-filter: none; }
  .popup-backdrop .SignUp .no-account-container {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-top: 24px;
    align-items: center; }
    .popup-backdrop .SignUp .no-account-container p {
      margin-right: 12px; }
    .popup-backdrop .SignUp .no-account-container .Button {
      margin: 0;
      padding: 8px 12px;
      font-size: 16px;
      background-color: #008db7; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.popup-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(8px) brightness(50%);
          backdrop-filter: blur(8px) brightness(50%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden; }
  .popup-backdrop .popup-spacer {
    width: 100%;
    height: 100%; }

@media screen and (max-width: 400px) {
  .popup-backdrop .popup-content {
    width: 100vw; } }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.LoadingBackdrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  max-width: 100%; }
  .LoadingBackdrop .LoadingPopup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 26px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    text-align: center;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    width: 100%;
    max-width: 400px; }
    .LoadingBackdrop .LoadingPopup h1 {
      margin-bottom: 24px; }
    .LoadingBackdrop .LoadingPopup input[type="text"],
    .LoadingBackdrop .LoadingPopup input[type="password"] {
      padding: 12px;
      font-size: 18px;
      border-radius: 12px;
      border: none;
      color: black; }
    .LoadingBackdrop .LoadingPopup .Button {
      margin-top: 12px;
      background-color: #00c4ff;
      -webkit-backdrop-filter: none;
              backdrop-filter: none; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.popup-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(8px) brightness(50%);
          backdrop-filter: blur(8px) brightness(50%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden; }
  .popup-backdrop .popup-spacer {
    width: 100%;
    height: 100%; }

@media screen and (max-width: 400px) {
  .popup-backdrop .popup-content {
    width: 100vw; } }

.ProgressBar {
  position: relative;
  height: 8px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px; }
  .ProgressBar .track,
  .ProgressBar .progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px; }
  .ProgressBar .track {
    -webkit-backdrop-filter: blur(4px) brightness(100%);
            backdrop-filter: blur(4px) brightness(100%); }
  .ProgressBar .progress {
    background-color: lightskyblue;
    transition: -webkit-transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out; }

:root {
  --frame-width: 100px;
  --frame-height: 100px;
  --frame-padding: 48px; }

.Game {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Game img.background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    object-fit: contain;
    object-position: center;
    display: flex;
    justify-content: center;
    align-items: center; }
  .Game .layout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Game .layout .layout-content {
      position: relative;
      width: 100px;
      width: var(--frame-width);
      height: 100px;
      height: var(--frame-height); }
    .Game .layout .background-overlay {
      -webkit-backdrop-filter: blur(8px) brightness(160%);
              backdrop-filter: blur(8px) brightness(160%);
      background-color: rgba(255, 255, 255, 0.1); }
    .Game .layout .frame {
      border: none; }
      .Game .layout .frame .Button.play-now {
        position: absolute;
        left: 48px;
        left: var(--frame-padding);
        bottom: 48px;
        bottom: var(--frame-padding);
        right: 48px;
        right: var(--frame-padding);
        background-color: #de3227 !important;
        box-shadow: 0 4px 18px 0 #50361e;
        border: solid 2px rgba(255, 255, 255, 0.1);
        padding: 24px; }
        .Game .layout .frame .Button.play-now.small {
          font-size: 18px; }
        .Game .layout .frame .Button.play-now.medium {
          font-size: 24px; }
        .Game .layout .frame .Button.play-now.large {
          font-size: 36px; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Level {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none; }
  .Level.show {
    display: inline-block; }
  .Level img.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center; }
  .Level .ball {
    position: absolute;
    width: 16.5%;
    height: 10.3125%;
    cursor: pointer;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .Level .ball:hover {
      -webkit-transform: rotate(20deg);
              transform: rotate(20deg);
      -webkit-transform: rotate(-20deg);
              transform: rotate(-20deg); }
    .Level .ball:active {
      transition: none; }
    .Level .ball.one {
      left: 60.8%;
      top: 11.8125%; }
      .Level .ball.one.par1 {
        display: none; }
      .Level .ball.one.par2 {
        display: none; }
      .Level .ball.one.par3 {
        display: none; }
      .Level .ball.one.par4 {
        display: none; }
    .Level .ball.two {
      left: 66.133333%;
      top: 29.708333%; }
    .Level .ball.three {
      left: 69.066667%;
      top: 47.291667%; }
    .Level .ball.four {
      left: 67.733333%;
      top: 64.208333%; }
    .Level .ball.five {
      left: 65.066667%;
      top: 80.395833%; }
      .Level .ball.five.par1 {
        display: none; }
      .Level .ball.five.par2 {
        display: none; }
      .Level .ball.five.par3 {
        display: none; }
    .Level .ball img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
      cursor: pointer; }
    .Level .ball .hole-strokes {
      display: none;
      cursor: pointer; }
    .Level .ball.selected {
      z-index: 999999999999; }
      .Level .ball.selected img {
        display: none; }
      .Level .ball.selected .hole-strokes {
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 400px;
        -webkit-backdrop-filter: blur(8px) brightness(110%);
                backdrop-filter: blur(8px) brightness(110%);
        border: solid 2px rgba(255, 255, 255, 0.5);
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        text-shadow: -3px -3px 6px rgba(255, 255, 255, 0.4), 3px 3px 6px rgba(0, 0, 0, 0.4); }
      .Level .ball.selected:hover {
        -webkit-transform: scale(1);
                transform: scale(1); }
  .Level .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.266);
    -webkit-backdrop-filter: blur(8px) brightness(50%);
            backdrop-filter: blur(8px) brightness(50%); }
    .Level .overlay.holeInOne, .Level .overlay.eagle, .Level .overlay.birdie, .Level .overlay.par, .Level .overlay.bogey, .Level .overlay.doubleBogey {
      display: none; }
      .Level .overlay.holeInOne.show, .Level .overlay.eagle.show, .Level .overlay.birdie.show, .Level .overlay.par.show, .Level .overlay.bogey.show, .Level .overlay.doubleBogey.show {
        display: flex; }
    .Level .overlay .boxey {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      border-radius: 12px;
      margin-left: 24px;
      max-width: 60%;
      overflow: hidden;
      border: 2px solid rgba(255, 255, 255, 0.6);
      opacity: 0.8;
      -webkit-backdrop-filter: blur(12px) brightness(160%);
              backdrop-filter: blur(12px) brightness(160%); }
      .Level .overlay .boxey h1 {
        width: 100%;
        padding: 12px;
        text-align: center;
        text-shadow: -2px -2px 4px rgba(255, 255, 255, 0.4), 2px 2px 4px rgba(0, 0, 0, 0.6); }
      .Level .overlay .boxey .innerBox {
        padding: 52px 24px;
        border-radius: 0;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        background-color: #e2d05c;
        border-top: 2px solid rgba(255, 255, 255, 0.6);
        background-color: rgba(255, 255, 255, 0.8);
        -webkit-backdrop-filter: blur(12px) brightness(160%);
                backdrop-filter: blur(12px) brightness(160%);
        width: 100%; }
        .Level .overlay .boxey .innerBox p {
          font-size: 1.8em;
          font-weight: 600;
          color: black;
          text-shadow: -4px -4px 8px white, 4px 4px 8px rgba(0, 0, 0, 0.6); }
  .Level .adSpace {
    width: 40%;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    left: 3%;
    bottom: 28%;
    bottom: 2%;
    opacity: 1;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.8);
    display: flex;
    overflow: hidden; }
    .Level .adSpace::before {
      content: " ";
      display: block;
      padding-top: 100%; }
    .Level .adSpace a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden; }
      .Level .adSpace a img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        overflow: hidden;
        width: 100%;
        height: 100%; }
  .Level .Button.next-hole {
    position: absolute;
    bottom: 2%;
    right: 4%;
    background-color: #de3227 !important;
    box-shadow: 0 4px 18px 0 #50361e;
    border: solid 2px rgba(255, 255, 255, 0.1);
    padding: 12px;
    cursor: pointer; }
    .Level .Button.next-hole.small {
      font-size: 18px; }
    .Level .Button.next-hole.medium {
      font-size: 24px; }
    .Level .Button.next-hole.large {
      font-size: 36px; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Score {
  position: absolute;
  width: 21%;
  height: 20.1%;
  top: 3%;
  left: 75.6%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.6);
  text-align: center;
  border: solid 2px rgba(255, 255, 255, 0.1);
  overflow: hidden;
  padding: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0); }
  .Score h3 {
    width: 100%;
    height: 100%;
    font-weight: 800;
    padding: 12px 0;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: -2px -2px 4px rgba(255, 255, 255, 0.4), 2px 2px 4px rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(12px) brightness(70%);
            backdrop-filter: blur(12px) brightness(70%); }
  .Score p {
    width: 100%;
    height: 100%;
    font-weight: 300;
    color: #003105;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(12px) brightness(160%);
            backdrop-filter: blur(12px) brightness(160%);
    text-shadow: -4px -4px 8px white, 4px 4px 8px rgba(0, 0, 0, 0.6); }
  .Score.small h3 {
    font-size: 12px; }
  .Score.small p {
    font-size: 32px; }
  .Score.medium h3 {
    font-size: 18px; }
  .Score.medium p {
    font-size: 44px; }
  .Score.large h3 {
    font-size: 24px; }
  .Score.large p {
    font-size: 56px; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.popup-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(8px) brightness(50%);
          backdrop-filter: blur(8px) brightness(50%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr 400px 1fr;
  grid-template-rows: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden; }
  .popup-backdrop .popup-spacer {
    width: 100%;
    height: 100%; }

@media screen and (max-width: 400px) {
  .popup-backdrop {
    grid-template-columns: auto 1fr auto; } }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.ConfirmPopup {
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 12px;
  text-align: center;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 12px; }
  .ConfirmPopup .ModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    padding: 36px;
    margin: 24px;
    max-width: 100%; }
  .ConfirmPopup h1 {
    margin-bottom: 24px; }
  .ConfirmPopup p {
    margin-bottom: 12px; }
  .ConfirmPopup .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    gap: 12px; }
    .ConfirmPopup .buttons-container .Button {
      background-color: #00c4ff;
      -webkit-backdrop-filter: none;
              backdrop-filter: none; }


* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.CourseDetails .content .body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 24px; }
  .CourseDetails .content .body .max-width {
    max-width: 1400px;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 24px;
    gap: 24px;
    padding: 24px 0;
    align-items: flex-start; }
    .CourseDetails .content .body .max-width .ContentPanel.main-content {
      overflow: hidden;
      grid-row: 1 / span 2;
      grid-column: 1; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content .title-container {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        grid-gap: 24px;
        gap: 24px;
        align-items: center; }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .title-container .Button {
          margin-bottom: 24px; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%; }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section .SkeletonText {
          margin-bottom: 8px; }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section p,
        .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ul,
        .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ol {
          margin-bottom: 24px;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.2em; }
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section p img,
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ul img,
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ol img {
            border-radius: 12px;
            box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.4); }
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section p a,
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ul a,
          .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ol a {
            padding: 24px 36px;
            background-color: rgba(255, 255, 255, 0.125);
            -webkit-backdrop-filter: blur(25px) brightness(200%);
                    backdrop-filter: blur(25px) brightness(200%);
            border-radius: 12px;
            display: inline-block;
            font-weight: 700;
            text-decoration: none;
            transition: -webkit-transform 0.2s ease-in-out;
            transition: transform 0.2s ease-in-out;
            transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section p a:hover,
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ul a:hover,
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ol a:hover {
              -webkit-backdrop-filter: blur(25px);
                      backdrop-filter: blur(25px);
              -webkit-transform: scale(1.05);
                      transform: scale(1.05); }
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section p a:active,
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ul a:active,
            .CourseDetails .content .body .max-width .ContentPanel.main-content .about-section ol a:active {
              background-color: rgba(255, 255, 255, 0.3);
              -webkit-backdrop-filter: blur(25px);
                      backdrop-filter: blur(25px);
              -webkit-transform: scale(0.95);
                      transform: scale(0.95);
              transition: none; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content h1,
      .CourseDetails .content .body .max-width .ContentPanel.main-content h2,
      .CourseDetails .content .body .max-width .ContentPanel.main-content h3,
      .CourseDetails .content .body .max-width .ContentPanel.main-content h4 {
        margin-bottom: 24px; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content h1 {
        font-size: 28px; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content .image-scroller {
        overflow-x: auto;
        overflow-y: hidden;
        -ms-scroll-snap-type: x proximity;
            scroll-snap-type: x proximity;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        padding: 24px 48px;
        margin: -24px -48px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */ }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .image-scroller::-webkit-scrollbar {
          display: none; }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .image-scroller .images {
          display: grid;
          grid-gap: 24px;
          gap: 24px;
          grid-template-columns: auto;
          grid-auto-columns: auto;
          grid-auto-flow: column; }
          .CourseDetails .content .body .max-width .ContentPanel.main-content .image-scroller .images .Image {
            scroll-snap-align: center; }
          .CourseDetails .content .body .max-width .ContentPanel.main-content .image-scroller .images .end-spacer {
            width: 24px; }
    .CourseDetails .content .body .max-width .ContentPanel.play-now {
      height: 400px;
      grid-row: 1;
      align-self: stretch;
      display: grid;
      grid-template-rows: auto auto 1fr auto;
      align-items: flex-end;
      position: relative;
      overflow: hidden; }
      .CourseDetails .content .body .max-width .ContentPanel.play-now img.background-image {
        position: absolute;
        grid-row: 200;
        grid-column: 200;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -2; }
      .CourseDetails .content .body .max-width .ContentPanel.play-now .background-overlay {
        position: absolute;
        grid-row: 200;
        grid-column: 200;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        -webkit-backdrop-filter: brightness(100%);
                backdrop-filter: brightness(100%); }
      .CourseDetails .content .body .max-width .ContentPanel.play-now .Button {
        grid-row: 4; }
    .CourseDetails .content .body .max-width .ContentPanel.quick-facts .quick-fact,
    .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info {
      display: grid;
      grid-template-columns: 16px auto 1fr;
      grid-gap: 16px;
      gap: 16px;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px; }
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .quick-fact svg,
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info svg {
        justify-self: center;
        align-self: center;
        text-align: center; }
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .quick-fact .title,
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .quick-fact .value,
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info .title,
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info .value {
        font-size: 14px;
        font-weight: 500; }
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .quick-fact .value,
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info .value {
        text-align: right; }
    .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info {
      grid-template-columns: auto 1fr; }
      .CourseDetails .content .body .max-width .ContentPanel.quick-facts .add-info:last-child {
        margin-bottom: 0; }

@media screen and (max-width: 1080px) {
  .CourseDetails .content .body .max-width {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    padding: 12px 0; }
    .CourseDetails .content .body .max-width .ContentPanel.main-content {
      grid-row: unset;
      grid-column: span 2; }
    .CourseDetails .content .body .max-width .ContentPanel.play-now {
      height: auto; }
    .CourseDetails .content .body .max-width .ContentPanel.quick-facts {
      grid-row: 1;
      align-self: stretch; } }

@media screen and (max-width: 640px) {
  .CourseDetails .content .body .max-width {
    grid-auto-flow: row;
    grid-template-rows: auto;
    grid-template-columns: 1fr; }
    .CourseDetails .content .body .max-width .ContentPanel.main-content {
      height: auto;
      grid-row: unset;
      grid-column: unset;
      padding: 24px; }
      .CourseDetails .content .body .max-width .ContentPanel.main-content .title-container {
        grid-template-rows: 1fr auto; }
        .CourseDetails .content .body .max-width .ContentPanel.main-content .title-container .Title {
          width: 100%;
          font-size: 12px; }
    .CourseDetails .content .body .max-width .ContentPanel.play-now {
      grid-row: 1;
      height: 400px; }
    .CourseDetails .content .body .max-width .ContentPanel.quick-facts {
      grid-row: 2; } }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(25px);
              backdrop-filter: blur(25px);
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Play {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #003105;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */ }
  .Play img.play-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .Play a {
    cursor: pointer !important;
    text-decoration: none !important; }

