.Image {
    img, .SkeletonText {
        border-radius: 12px;
        width: 300px;
        height: 200px;
    }
    
    img {
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.4);
        object-fit: cover;
        object-position: center;
    }

    .SkeletonText {
    }
}