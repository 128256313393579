* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.MenuItem {
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding: 12px 24px;
  display: inline-block;
  border-radius: 8px;
  backdrop-filter: brightness(95%);
  margin: 12px 0 0 0; }
  .MenuItem:hover {
    backdrop-filter: brightness(15%); }
  .MenuItem.Button {
    color: white;
    padding: 12px;
    margin: 12px 0; }
  .MenuItem.Divider {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0;
    margin: 12px 0;
    max-width: 100px;
    background-color: transparent;
    backdrop-filter: none;
    cursor: default; }
