$radius: 32px;

.Tile {
    position: relative;
    text-align: center;
    border-radius: $radius;
    transition: transform 0.355s ease-in-out;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transition: all ease-in-out .325s;

    &:before {
        content: " ";
        display: block;
        padding-top: 75%;
    }

    &:hover {
        // transform: scale(1.035);
        // box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 1);
        transition: all ease-in-out .225s;
        cursor: pointer;
    }

    &:hover .tile-background, .tile-background:hover {
        filter: blur(8px);
        transition: all .555s ease-in-out;
    }

    .tile-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        filter: blur(22px);
        transition: all .355s ease-in-out;
    }

    .tile-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        // backdrop-filter: blur(22px);

        &.light {
            background-color: rgba(255, 255, 255, 0.15);
            background-color: transparent;
            backdrop-filter: blur(4px) brightness(150%);
        }
    }

    .sponsor {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        padding: 24px;
        overflow: hidden;

        img {
            width: 100%;
            align-self: center;
            object-fit: contain;
            z-index: 1;
            filter: drop-shadow(0 0 12px rgba(0, 0, 0, 1));
        }

        h1 {
            filter: drop-shadow(0 0 12px rgba(0, 0, 0, 1));
        }
    }

    h1 {
        color: white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}