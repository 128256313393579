.StripeStyle {
    background-color: white;
    padding: 8px;
    margin: 8px 0;
    margin-top: 4px;
    border-radius: 8px;
}
.ButtonContain {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .BuyNow {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        color: #ffffff !important;
        font-weight: 600;
        font-size: 18px !important;
        background-color: #00c4ff;
        padding: 14px;
        border: none;
        border-radius: 12px;
        margin: 8px;
        cursor: pointer;
    }
}