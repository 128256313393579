:root {
    --frame-width: 100px;
    --frame-height: 100px;
    --frame-padding: 48px;
}

.Game {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img.background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        object-fit: contain;
        object-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .layout {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .layout-content {
            position: relative;
            width: var(--frame-width);
            height: var(--frame-height);
        }

        .background-overlay {
            backdrop-filter: blur(8px) brightness(160%);
            background-color: rgba(255, 255, 255, 0.1);
            // box-shadow: 0 0 48px 0 rgba(0, 0, 0, 1);
        }

        .frame {
            border: none;

            .Button {
                &.play-now {
                    position: absolute;
                    left: var(--frame-padding);
                    bottom: var(--frame-padding);
                    right: var(--frame-padding);
                    background-color: #de3227 !important;
                    box-shadow: 0 4px 18px 0 #50361e;
                    border: solid 2px rgba(255, 255, 255, 0.1);
                    padding: 24px;

                    &.small {
                        font-size: 18px;
                    }

                    &.medium {
                        font-size: 24px;
                    }

                    &.large {
                        font-size: 36px;
                    }
                }
            }
        }
    }
}
