.PricingOption {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 8px 0;
    border-radius: 8px;
    background-color: white;
    width: 30%;
    min-height: 140px;
    text-align: center;
    padding: 16px;
    .Numbers {
        .CreditAmount {
            display: flex;
            flex-direction: row;
            align-items: baseline;
        }
    }
    .Radio {
        border: 1px solid #7d8385;
        width: 24px;
        height: 24px;
        border-radius: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white !important;
        img {
            width: 100%;
            padding: 2px;
        }

        &.selected {
            background-color: #1293ff;
        }
    }
    h2 {
        color: black !important;
    }
    p {
        color: black !important;
    }
}

@media only screen and (max-width: 600px) {
    .PricingOption {
        h2 {
            font-size: 16px;
        }
        p {
            font-size: 12px;
        }
    }
}