* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.ConfirmPopup {
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 12px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 12px; }
  .ConfirmPopup .ModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    padding: 36px;
    margin: 24px;
    max-width: 100%; }
  .ConfirmPopup h1 {
    margin-bottom: 24px; }
  .ConfirmPopup p {
    margin-bottom: 12px; }
  .ConfirmPopup .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px; }
    .ConfirmPopup .buttons-container .Button {
      background-color: #00c4ff;
      backdrop-filter: none; }
