.ProfileMini {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    align-items: center;
    gap: 12px;
    pointer-events: none;

    .profile-image {
        width: 32px;
        height: 32px;
        border-radius: 500px;
        border: solid 2px white;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        .name-title {
            pointer-events: none;
        }
        
        .arrow {
            width: 24px;
            height: 24px;
            border-radius: 500px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
        }
    }
}
