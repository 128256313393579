* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

:root {
  --max-width: 400px; }

.Search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 18px;
  width: var(--max-width);
  transition: width 0.2s ease-in-out; }
  .Search.collapse {
    width: 48px;
    height: 48px;
    padding: 0;
    justify-content: center; }
    .Search.collapse .icon {
      margin-right: 0; }
  .Search .icon {
    margin-right: 12px; }
  .Search .SearchContainer {
    animation: fadeIn ease-in-out .655s;
    -webkit-animation: fadeIn ease .655s;
    -moz-animation: fadeIn ease .655s;
    -o-animation: fadeIn ease .655s;
    -ms-animation: fadeIn ease .655s; }
    .Search .SearchContainer .Results {
      background-color: #263a28;
      border-radius: 12px;
      width: 100%;
      max-height: 300px;
      overflow-y: scroll;
      position: absolute;
      margin: 10px 0;
      left: 0;
      right: 0;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      animation: fadeIn ease-in-out 2s;
      -webkit-animation: fadeIn ease 2s;
      -moz-animation: fadeIn ease 2s;
      -o-animation: fadeIn ease 2s;
      -ms-animation: fadeIn ease 2s; }
      .Search .SearchContainer .Results a:link {
        text-decoration: none; }
    .Search .SearchContainer .Results::-webkit-scrollbar {
      display: none; }
  .Search input[type="text"] {
    height: 48px;
    width: 100%;
    border: none;
    background-color: transparent;
    caret-color: #00c4ff;
    font-size: 16px;
    color: white;
    font-weight: 400; }
    .Search input[type="text"]:focus {
      outline: none; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
