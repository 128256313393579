* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Level {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none; }
  .Level.show {
    display: inline-block; }
  .Level img.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center; }
  .Level .ball {
    position: absolute;
    width: 16.5%;
    height: 10.3125%;
    cursor: pointer;
    transition: transform 0.2s ease-in-out; }
    .Level .ball:hover {
      transform: rotate(20deg);
      transform: rotate(-20deg); }
    .Level .ball:active {
      transition: none; }
    .Level .ball.one {
      left: 60.8%;
      top: 11.8125%; }
      .Level .ball.one.par1 {
        display: none; }
      .Level .ball.one.par2 {
        display: none; }
      .Level .ball.one.par3 {
        display: none; }
      .Level .ball.one.par4 {
        display: none; }
    .Level .ball.two {
      left: 66.133333%;
      top: 29.708333%; }
    .Level .ball.three {
      left: 69.066667%;
      top: 47.291667%; }
    .Level .ball.four {
      left: 67.733333%;
      top: 64.208333%; }
    .Level .ball.five {
      left: 65.066667%;
      top: 80.395833%; }
      .Level .ball.five.par1 {
        display: none; }
      .Level .ball.five.par2 {
        display: none; }
      .Level .ball.five.par3 {
        display: none; }
    .Level .ball img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
      cursor: pointer; }
    .Level .ball .hole-strokes {
      display: none;
      cursor: pointer; }
    .Level .ball.selected {
      z-index: 999999999999; }
      .Level .ball.selected img {
        display: none; }
      .Level .ball.selected .hole-strokes {
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 400px;
        backdrop-filter: blur(8px) brightness(110%);
        border: solid 2px rgba(255, 255, 255, 0.5);
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        text-shadow: -3px -3px 6px rgba(255, 255, 255, 0.4), 3px 3px 6px rgba(0, 0, 0, 0.4); }
      .Level .ball.selected:hover {
        transform: scale(1); }
  .Level .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.266);
    backdrop-filter: blur(8px) brightness(50%); }
    .Level .overlay.holeInOne, .Level .overlay.eagle, .Level .overlay.birdie, .Level .overlay.par, .Level .overlay.bogey, .Level .overlay.doubleBogey {
      display: none; }
      .Level .overlay.holeInOne.show, .Level .overlay.eagle.show, .Level .overlay.birdie.show, .Level .overlay.par.show, .Level .overlay.bogey.show, .Level .overlay.doubleBogey.show {
        display: flex; }
    .Level .overlay .boxey {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      border-radius: 12px;
      margin-left: 24px;
      max-width: 60%;
      overflow: hidden;
      border: 2px solid rgba(255, 255, 255, 0.6);
      opacity: 0.8;
      backdrop-filter: blur(12px) brightness(160%); }
      .Level .overlay .boxey h1 {
        width: 100%;
        padding: 12px;
        text-align: center;
        text-shadow: -2px -2px 4px rgba(255, 255, 255, 0.4), 2px 2px 4px rgba(0, 0, 0, 0.6); }
      .Level .overlay .boxey .innerBox {
        padding: 52px 24px;
        border-radius: 0;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        background-color: #e2d05c;
        border-top: 2px solid rgba(255, 255, 255, 0.6);
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(12px) brightness(160%);
        width: 100%; }
        .Level .overlay .boxey .innerBox p {
          font-size: 1.8em;
          font-weight: 600;
          color: black;
          text-shadow: -4px -4px 8px white, 4px 4px 8px rgba(0, 0, 0, 0.6); }
  .Level .adSpace {
    width: 40%;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    left: 3%;
    bottom: 28%;
    bottom: 2%;
    opacity: 1;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.8);
    display: flex;
    overflow: hidden; }
    .Level .adSpace::before {
      content: " ";
      display: block;
      padding-top: 100%; }
    .Level .adSpace a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden; }
      .Level .adSpace a img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        overflow: hidden;
        width: 100%;
        height: 100%; }
  .Level .Button.next-hole {
    position: absolute;
    bottom: 2%;
    right: 4%;
    background-color: #de3227 !important;
    box-shadow: 0 4px 18px 0 #50361e;
    border: solid 2px rgba(255, 255, 255, 0.1);
    padding: 12px;
    cursor: pointer; }
    .Level .Button.next-hole.small {
      font-size: 18px; }
    .Level .Button.next-hole.medium {
      font-size: 24px; }
    .Level .Button.next-hole.large {
      font-size: 36px; }
