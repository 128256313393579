.ProgressBar {
    position: relative;
    height: 8px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;

    .track,
    .progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 8px;
    }

    .track {
        backdrop-filter: blur(4px) brightness(100%);
    }

    .progress {
        background-color: lightskyblue;
        transition: transform 0.1s ease-in-out;
    }
}
