* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.popup-backdrop .SignUp {
  padding: 36px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  text-align: left;
  transform: translateY(0%);
  transition: transform 0.2s ease-in-out;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-template-rows: auto;
  gap: 12px;
  grid-row: 2;
  grid-column: 2;
  overflow: auto;
  max-height: 100%; }
  .popup-backdrop .SignUp h1 {
    margin-bottom: 24px; }
  .popup-backdrop .SignUp input[type="text"],
  .popup-backdrop .SignUp input[type="password"],
  .popup-backdrop .SignUp input[type="number"] {
    padding: 12px;
    font-size: 18px;
    border-radius: 12px;
    border: none;
    color: black; }
  .popup-backdrop .SignUp .Button {
    margin-top: 12px;
    background-color: #00c4ff;
    backdrop-filter: none; }
  .popup-backdrop .SignUp .no-account-container {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-top: 24px;
    align-items: center; }
    .popup-backdrop .SignUp .no-account-container p {
      margin-right: 12px; }
    .popup-backdrop .SignUp .no-account-container .Button {
      margin: 0;
      padding: 8px 12px;
      font-size: 16px;
      background-color: #008db7; }

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.popup-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px) brightness(50%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden; }
  .popup-backdrop .popup-spacer {
    width: 100%;
    height: 100%; }

@media screen and (max-width: 400px) {
  .popup-backdrop .popup-content {
    width: 100vw; } }
