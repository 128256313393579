@import "../App.scss";

.popup-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px) brightness(50%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr auto 1fr;
    justify-content: center;
    align-items: center;
    padding: 0;
    overflow: hidden;

    .popup-spacer {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 400px) {
    .popup-backdrop {
        // grid-template-columns: 0 auto 0;
        // grid-template-rows: 0 auto 1fr;
        .popup-content {
            width: 100vw;
        }
    }
}
