// Sizes
$header-height: 72px;
$header-button-height: 48px;

// Colors
$menu-background: #263a28;
$background-color: #003105;
$gold: #e6bf3b;

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0;
}

img {
  pointer-events: none;
}

body {
  background-color: $background-color;
}

.App {
  text-align: center;
}

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px;
}

.clickable {
  cursor: pointer;

  &.animated {
    transition: transform 0.2s ease-in-out;

    &:hover {
      // background-color: rgba(255,255,255,0.3);
      backdrop-filter: blur(25px);
      transform: scale(1.05);
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
