* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Button {
  padding: 12px 18px;
  background-color: rgba(255, 255, 255, 0.125);
  color: white;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  backdrop-filter: blur(20px) brightness(180%);
  transition: transform 0.2s ease-in-out;
  text-decoration: none; }
  .Button:hover {
    backdrop-filter: blur(25px); }
  .Button:active {
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(25px);
    transform: scale(0.95);
    transition: none; }
  .Button.highlight {
    color: #e6bf3b !important; }
    .Button.highlight div {
      color: #e6bf3b !important; }
    .Button.highlight p {
      color: #e6bf3b !important; }
    .Button.highlight svg {
      color: #e6bf3b !important; }
      .Button.highlight svg path {
        color: #e6bf3b !important; }
  .Button.darkMode {
    backdrop-filter: blur(20px) brightness(80%);
    background-color: rgba(0, 0, 0, 0.125); }
  .Button .divider {
    width: 12px;
    height: 12px;
    background-color: transparent; }
