* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0; }

img {
  pointer-events: none; }

body {
  background-color: #003105; }

.App {
  text-align: center; }

.error-message {
  color: white;
  background-color: tomato;
  font-weight: 700;
  padding: 8px;
  border-radius: 6px; }

.clickable {
  cursor: pointer; }
  .clickable.animated {
    transition: transform 0.2s ease-in-out; }
    .clickable.animated:hover {
      backdrop-filter: blur(25px);
      transform: scale(1.05); }
    .clickable.animated:active {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(25px);
      transform: scale(0.95);
      transition: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.Score {
  position: absolute;
  width: 21%;
  height: 20.1%;
  top: 3%;
  left: 75.6%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.6);
  text-align: center;
  border: solid 2px rgba(255, 255, 255, 0.1);
  overflow: hidden;
  padding: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0); }
  .Score h3 {
    width: 100%;
    height: 100%;
    font-weight: 800;
    padding: 12px 0;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: -2px -2px 4px rgba(255, 255, 255, 0.4), 2px 2px 4px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(12px) brightness(70%); }
  .Score p {
    width: 100%;
    height: 100%;
    font-weight: 300;
    color: #003105;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(12px) brightness(160%);
    text-shadow: -4px -4px 8px white, 4px 4px 8px rgba(0, 0, 0, 0.6); }
  .Score.small h3 {
    font-size: 12px; }
  .Score.small p {
    font-size: 32px; }
  .Score.medium h3 {
    font-size: 18px; }
  .Score.medium p {
    font-size: 44px; }
  .Score.large h3 {
    font-size: 24px; }
  .Score.large p {
    font-size: 56px; }
